/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
// import ParseContent from 'components/shared/ParseContent'
import Hero from 'components/shared/Hero'
import Breadcrumb from 'components/shared/Breadcrumb'
import ShadowBox from 'components/shared/ShadowBox'
import PartnerSlider from 'components/elements/PartnerSlider'
import { Pro, Work } from './solutions'
import Plus from 'img/icon/plus.inline.svg'
import styled from 'styled-components'
import GravityForm from 'components/GravityForm'
import RelatedCases from 'components/shared/RelatedCases'
import ButtonDefault from 'components/elements/ButtonDefault'

const FormWrapper = styled.div`
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px;
`

const StyledTitle = styled.h2`
  font-size: 36px;
  font-weight: 800;
`

const Check = styled.div`
  & h2 {
    font-weight: ${(props) => props.theme.font.weight.xl};
    font-size: ${(props) => props.theme.font.size.xxl};
  }
`

const PageTemplate = ({
  data: {
    page: { path, acf, yoast_meta: yoast, title },
  },
  pageContext,
}) => {
  if (title.toLowerCase() !== 'sd-wan') {
    title = title.toLowerCase()
  }

  return (
    <Layout>
      <SEO yoast={yoast} path={path} />
      <Hero className="mb-lg-4" content={acf.banner} />
      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />
      <div className="container py-5">
        {acf.check.description.length > 10 && (
          <Check className="py-5 mb-5">
            <h2>Doe hier de postcodecheck</h2>
            <p>
              Zakelijk glasvezel internet voor een vast maandelijks bedrag. Doe
              de postcodecheck en bekijk wat er mogelijk is op uw locatie.
            </p>
            <ButtonDefault className="mt-3" to="/offerte-aanvragen/">
              Doe de check
            </ButtonDefault>
          </Check>
        )}

        <div className="row py-5">
          <div className="col-lg-6 d-flex justify-content-center justify-content-lg-start mt-lg-5">
            <ShadowBox delay={0.4} content={acf.content} />
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <div className="pb-5 pl-lg-5 pt-lg-0 pt-5">
              {acf.content.pros.map((pro) => (
                <Pro className="d-flex align-items-center pb-5">
                  <Plus className="mr-3" />
                  {parse(pro.pro)}
                </Pro>
              ))}
            </div>
          </div>

          <div className="col-12">
            <div className="row pt-lg-5 pb-5 justify-content-center">
              <Work className="col-lg-6 py-4">
                {parse(acf.content.work_everywhere)}
              </Work>
              <div className="col-lg-6 pb-lg-0 pb-4 pt-lg-5">
                <RelatedCases cases={acf.content.cases} />
              </div>
            </div>
          </div>

          <div className="py-5 d-flex col-lg-10 mx-auto">
            <FormWrapper className="p-lg-5 p-3">
              <StyledTitle className="text-center">
                Meer weten over {title}?<br />
                Neem vrijblijvend contact op!
              </StyledTitle>
              <GravityForm className="mt-5" enableConsent id={14} />
            </FormWrapper>
          </div>

          <div className="py-5">
            <PartnerSlider />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        check {
          description
        }

        banner {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
          description
        }
        content {
          description
          work_everywhere
          pros {
            pro
          }
          cases {
            title
            path
            wordpress_id
            acf {
              preview {
                description
                image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 700) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
