/* eslint-disable react/no-array-index-key */
import React, { useState, useLayoutEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import ButtonMinimal from 'components/elements/ButtonMinimal'
import CustomLink from 'components/shared/CustomLink'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'
import parse from 'html-react-parser'

const StyledBestBlogs = styled.div`
  @media screen and (max-width: 991px) {
    padding: 0 10px 0 10px;
  }
`

const InnerBlogs = styled.div`
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  padding: 40px 30px 5px 30px;
  background-color: ${props => props.theme.color.face.light};
  max-width: 417px;

  h2 {
    font-size: ${props => props.theme.font.size.xml};
    font-weight: ${props => props.theme.font.weight.xl};
  }
`

const Description = styled.div`
  min-height: 55px;

  p {
    font-size: ${props => props.theme.font.size.s};
  }
`

const BlogShadow = styled(motion.div)`
  z-index: -1;
  position: absolute;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.color.gradient.main};
`

const Post = styled(motion.div)`
  h3 {
    font-size: ${props => props.theme.font.size.ms};
    font-weight: ${props => props.theme.font.weight.xl};
    width: 90%;
  }

  ${props => props.line && css`
    border-top: 1px solid ${props.theme.color.face.border};
  `}
`

const StyledImg = styled(Img)`
  border-radius: 10px;
  z-index: 1;
  position: relative;
  height: 100px;
`

const ImgContainer = styled(motion.div)`
  position: relative;
  max-height: 200px;
`

const ImgShadow = styled(motion.div)`
  height: 97%;
  width: 97%;
  position: absolute;
  background: ${props => props.theme.color.gradient.main};
  border-radius: 10px;
`

const OtherBranches = ({ cases, className }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.5,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if(isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <StyledBestBlogs className={`d-flex justify-content-center justify-content-lg-end ${className ? `${className}` : ``}`}>
      <InnerBlogs>
        <h2>Ontdek onze cases</h2>
        {cases.map((c, index) => (
          <Post 
            line={index !== 0}
            key={c.wordpress_id} 
            className="pb-lg-4 pb-2 pt-lg-5 pt-3"
            whileHover="hover"
            initial="rest"
            animate={{}}
          >
            <div className="row justify-content-center">
              <div className="col-md-10">
                <CustomLink to={c.path}>
                  <ImgContainer>
                    <StyledImg loading="eager" fadeIn={false} fluid={c.acf.preview.image.localFile.childImageSharp.fluid} alt="" />
                    <ImgShadow 
                      variants={{rest: { right: 0, bottom: 0 }, hover: { right: -10, bottom: -10 }}}
                    />
                  </ImgContainer>
                </CustomLink>
              </div>

              <div className="col-md-10 pt-3">
                <h3 className="font-weight-xl pb-lg-0 pb-2 mb-0">{c.title}</h3>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="pt-2">
                  <Description>
                    {parse(c.acf.preview.description)}
                  </Description>
                </div>
                <div className="d-flex pt-2 justify-content-end">
                  <ButtonMinimal to={c.path} icon="arrow">
                    <p>Meer informatie</p>
                  </ButtonMinimal>
                </div>
              </div>
            </div>
          </Post>
        ))}
        <BlogShadow
          ref={ref}
          initial={{ right: 0, bottom: 0 }}
          animate={{ right: -40, bottom: -40 }}
          transition={{ delay: 0.3, type: 'spring' }}
        />
      </InnerBlogs>
    </StyledBestBlogs>
  )
}

export default OtherBranches